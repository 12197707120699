export interface IGeometry {
  type: string;
  coordinates: number[];
}

export interface IGeoJson {
  type: string;
  geometry: IGeometry;
  properties?: any;
  $key?: string;
}

export interface IProperties {
  object_no: string;
  title: string;
  artist: string;
  date: string;
  circa: string;
  medium: string;
  dimensions: string;
  description: string;
  img: string;
  tags: string;
  featured_search: string;
  document1: string;
  document1_thumb: string;
  document1_description: string;
  document2: string;
  document2_thumb: string;
  document2_description: string;
  document3: string;
  document3_thumb: string;
  document3_description: string;
  links: string;
}

export class GeoJson implements IGeoJson {
  type = 'Feature';
  geometry: IGeometry;

  constructor(coordinates, public properties?) {
    this.geometry = {
      type: 'Point',
      coordinates: coordinates
    }
  }
}

export class FeatureCollection {
  type = 'FeatureCollection'
  constructor(public features: Array<GeoJson>) {}
}
