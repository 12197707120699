import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MapBoxComponent } from './map-box/map-box.component';
import { IProperties } from './map/map';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private cd: ChangeDetectorRef) {
  }

  @ViewChild('topbar')
  topbar: TopbarComponent;

  @ViewChild('mapBox')
  mapBox: MapBoxComponent;

  @ViewChild('sidebar')
  sidebar: SidebarComponent;

  showFloatingSidebar: boolean = false;

  breakpointIndex: number;
  setBreakpointIndex(event) {
    this.breakpointIndex = event;
  }

  onSidebarToggleClick(event) {
    this.showFloatingSidebar = event;
    setTimeout(() => {
      this.mapBox.refresh();
      this.sidebar.refreshSlider();
      this.cd.detectChanges();
}, 1);
  }

  filterMapPieces(event) {
    this.mapBox.filter(event);
  }

  selectedPieceDetails: IProperties = null;

  pieceSelected(event: IProperties) {
    this.topbar.showBackButton();
    this.selectedPieceDetails = event;
    this.cd.detectChanges();
  }

  onBackButtonClick() {
    if (this.selectedPieceDetails) {
      this.selectedPieceDetails = null;
    }
    if (this.aboutSelected) {
      this.aboutSelected = false;
    }

    setTimeout(() => {
      this.cd.detectChanges();
      this.sidebar.refreshSlider();
      this.mapBox.refresh();
    }, 1);
  }

  showNativeTerritories(event) {
    this.mapBox.showNativeTerritories(event);
  }

  onTagClick(event) {
    this.sidebar.onTagInput(event);
    this.topbar.onBackButtonClick();
  }

  aboutSelected: boolean = false;

  onAboutSelected() {
    this.aboutSelected = true;
    this.topbar.showBackButton();
    //this.cd.detectChanges();
  }
}
