import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Options } from 'ng5-slider';

// TODO MOVE OUT
class Search {
  searchTerm: string = '';
  exploreSelection: string = '';
  dateRange: any = {
    min: 0,
    max: 100
  } 
}

const MinYear: number = 1795;
const CurrentYear = (new Date()).getFullYear();

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  featuredSearch = [
    "19th-Century London",
    "Great Lakes",
    "Inuit Art",
    "London Regionalism",
    "Scenes of London",
    "The Group of Seven",
    "The Thames River",
    "Walking Tour of Downtown London",
    "Women Artists"
  ];

  @Output()
  searchEmitter: EventEmitter<Search> = new EventEmitter();

  // Search Bar
  searchTerm: string = '';
  activeSearchTerm: string = '';

  // Explore Select
  exploreSelection: string = '';

  // Range Slider
  minValue: number = MinYear;
  maxValue: number = CurrentYear;
  options: Options = {
    floor: MinYear,
    ceil: CurrentYear,
    step: 1
  };

  defaultMinDate: number = MinYear;
  defaultMaxDate: number = CurrentYear;

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  resettable: boolean = false;

  @Output()
  showNativeTerritories: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  aboutSelected: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

  onAboutClick() {
    this.aboutSelected.emit();
  }

  onTeachersGuideClick(link: string): void {
    window.open(link, '_blank');
  }

  private checkResettable() {
    this.resettable = (this.searchTerm ? true : false) || (this.activeSearchTerm ? true : false) || (this.exploreSelection ? true : false) || (
      this.maxValue !== this.defaultMaxDate ||
      this.minValue !== this.defaultMinDate
    );
  }
  onSearchTermInput() {
    this.checkResettable();
  }

  onSearchTermKeyDown(event) {
    if (event.keyCode === 13) {
      this.onSearchClick();
    }
  }  

  onTagInput(tag) {
    this.searchTerm = tag;
    this.checkResettable();
    this.onSearchClick();
  }

  onSearchClick() {
    this.activeSearchTerm = this.searchTerm;
    this.searchTerm = '';
    this.checkResettable();
    this.emitFilter();
  }

  onExploreSelectionChange() {
    this.checkResettable();
    this.emitFilter();
  }

  onDateRangeFilterChange() {
    this.checkResettable();
    this.emitFilter();
  }

  private emitFilter() {
    let toEmit: any = {
    };
    if (this.activeSearchTerm) {
      toEmit.searchTerm = this.activeSearchTerm;
    }
    if (this.exploreSelection) {
      toEmit.exploreSelection = this.exploreSelection;
    }
    if (this.maxValue !== this.defaultMaxDate ||
        this.minValue !== this.defaultMinDate) {
      toEmit.dateRange = {
        max: this.maxValue,
        min: this.minValue
      };
    }
    this.searchEmitter.emit(toEmit);
  }

  refreshSlider() {
    this.manualRefresh.emit();
  }

  onShowIndigenousTerritoriesCheckboxChange(isChecked) {
    this.showNativeTerritories.emit(isChecked);
  }

  onResetClick() {
    this.searchTerm = '';
    this.activeSearchTerm = '';

    this.exploreSelection = '';

    this.maxValue = this.defaultMaxDate;
    this.minValue = this.defaultMinDate;

    this.resettable = false;
    this.emitFilter();
  }
}
