import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { IProperties } from '../map/map';

@Component({
  selector: 'app-piece-details',
  templateUrl: './piece-details.component.html',
  styleUrls: ['./piece-details.component.scss']
})
export class PieceDetailsComponent implements OnInit {

  @Input('properties')
  properties: IProperties;

  tags: string[] = [];

  constructor() {
  }

  ngOnInit() {
    this.tags = this.properties.tags
      .split(',')
      .map(function(tag) {
        return tag.trim();
      });

    if (this.tags.length === 1 && !this.tags[0]) {
      this.tags = [];
    }
  }

  @Output()
  searchTermClicked: EventEmitter<string> = new EventEmitter<string>();

  onArtistClick(artist) {
    this.searchTermClicked.emit(artist)
  }

  onTagClick(tag) {
    this.searchTermClicked.emit(tag)
  }
}
