import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { MapService } from '../map/map.service';
import { GeoJson, FeatureCollection, IProperties } from '../map/map';


// Hack
declare global {
  interface Window { MyNamespace: any; }
}

window.MyNamespace = window.MyNamespace || {};
// End Hack

@Component({
  selector: 'map-box',
  templateUrl: './map-box.component.html',
  styleUrls: ['./map-box.component.scss'],
  providers: [MapService]
})
export class MapBoxComponent implements OnInit{

  @Output()
  pieceSelected: EventEmitter<IProperties> = new EventEmitter();

  selectedPiece: IProperties;

  // default settings
  map: mapboxgl.Map;
  style: string = 'mapbox://styles/mlcartography/cjv87e6jh1hop1fpn4ufwschb';
  zoom: number = 12;
  defaultLng: number = -81.2550;
  defaultLat: number = 42.9826;

  // data
  source: any;
  data: any;
  filteredData: any;

  constructor(private mapService: MapService) {
  }

  ngOnInit() {
    this.initializeMap();
  }

  private initializeMap() {
    // locate the user
    if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(position => {
        this.defaultLng = position.coords.longitude;
        this.defaultLat = position.coords.latitude;
        this.map.flyTo({
          center: [this.defaultLng, this.defaultLat]
        })
      });
    }

    this.buildMap();
  }

  buildMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: this.zoom,
      center: [
        this.defaultLng,
        this.defaultLat
      ]
    });


    // Add map controls
    this.map.addControl(new mapboxgl.NavigationControl());


    // Add realtime pieces data on map load
    this.map.on('load', (event) => {

      this.map.loadImage("assets/marker-museum.png", (error, markerMuseum) => {
        if (error) {
          alert(error);
          throw error;
        }

        this.map.loadImage("assets/marker-general.png", (error, markerGeneral) => {
          if (error) {
            alert(error);
            throw error;
          }
  
          this.buildMapPart2(markerMuseum, markerGeneral);
        });
      });
    });
  }
  popup: mapboxgl.Popup = null;

  private buildMapPart2(markerMuseum, markerGeneral) {
    this.map.addImage("marker-museum", markerMuseum);
    this.map.addImage("marker-general", markerGeneral);

    // create territories layers
    this.map.addLayer({
      'id': 'native-territories',
      'type': 'fill',
      'source': {
        'type': 'geojson',
        'data': this.mapService.getNativeTerritories()
      },
      'paint': {
        'fill-color': ['get', 'color'],
        'fill-opacity': 0.4
      }
    });

    // create territories layers
    this.map.addLayer({
      'id': 'native-territories-labels',
      'type': 'symbol',
      'source': {
        'type': 'geojson',
        'data': this.mapService.getNativeTerritories()
      },
      'layout': {
        'text-field': ['get', 'Name']
      }
    });

    this.map.setLayoutProperty('native-territories', 'visibility', 'none');
    this.map.setLayoutProperty('native-territories-labels', 'visibility', 'none');

    // register source
    this.map.addSource('pieces', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    // get source
    this.source = this.map.getSource('pieces');

    // fetch data from service
    this.data = this.mapService.getMarkers();

    // run filter which sets data
    this.filter({}, true);

    // create map layers with realtime data
    this.map.addLayer({
      id: 'pieces',
      source: 'pieces',
      type: 'symbol',
      layout: {
        'icon-image': 'marker-general',
        'icon-allow-overlap': true,
        'icon-anchor': 'bottom'
      }
    });

    // Add Museum after adding pieces layer so it can show on top
    this.map.addLayer({
      "id": "museum",
      "type": "symbol",
      "source": {
        "type": "geojson",
        "data": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [
                -81.2550,
                42.9826
              ]
            }
          }]
        }
      },
      "layout": {
        "icon-image": "marker-museum",
        'icon-allow-overlap': true,
        'icon-anchor': 'bottom'
      }
    });

    const showPopup = (e) => {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var properties = e.features[0].properties;

      this.selectedPiece = properties;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      let date = 'Undated';
      if (properties.date && properties.date !== "null") {
        date = properties.date;
        if (properties.circa !== "null") {
          date = 'c. ' + date;
        }
      }        
      date = '<div>' + date + '</div>';

      this.popup = new mapboxgl.Popup({offset: [0, -22]})
        .setLngLat(coordinates)
        .setHTML(
          '<div style="width: 202px;">' +
            '<div style="width: 187px; line-height: normal"><b>' + properties.title + '</b></div>' +
            '<div style="line-height: normal; display: flex; flex-flow: row nowrap; justify-content: space-between;">' +
              '<div>' + properties.artist + '</div>' +
              date +
            '</div>' +
            '<div style="height: 16px;"></div>' +
            '<div class="border" style="display: inline-block" onclick="window.MyNamespace.popupClickEvent()">' +
              '<img src="' + properties.img + '" width=200 height=200 style="cursor: pointer;"/>' +
            '</div>' +
          '</div>')
        .addTo(this.map);
    };

    // When a click event occurs on a feature in the places layer, open a popup at the
    // location of the feature, with description HTML from its properties.
    this.map.on('click', 'pieces', (e) => {
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        showPopup(e);
      } else {
        showSelectedPiece();
      }
    });

    // Change the cursor to a pointer when the mouse is over the pieces layer.
    this.map.on('mouseenter', 'pieces', (e) => {
      showPopup(e);
      this.map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    this.map.on('mouseleave', 'pieces', () => {
      if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        this.map.getCanvas().style.cursor = '';
        this.popup.remove();
      }
    });

    const showSelectedPiece = () => {
      this.pieceSelected.emit(this.selectedPiece);
      this.popup.remove();
    };

    window.MyNamespace.popupClickEvent = () => {
      showSelectedPiece();
    };

    this.refresh();
  }

  refresh() {
    this.map.resize();
  }

  flyTo(data: GeoJson) {
    this.map.flyTo({
      center: data.geometry.coordinates
    });
  }

  filter(filter: any, skipFitBounds: boolean = false) {
    if (this.popup) {
      this.popup.remove();
    }

    // get filtered data set
    var filtered = this.data.filter((piece) => {
      return this.filterPiece(piece, filter);
    });

    // set data with filtered data
    this.source.setData(
      this.mapService.wrapMarkers(filtered)
    );

    if (!skipFitBounds) {
      var minLng = this.getMinLng(filtered);
      var minLat = this.getMinLat(filtered);
      var maxLng = this.getMaxLng(filtered);
      var maxLat = this.getMaxLat(filtered);
      var deltaLng = Math.abs(maxLng - minLng);
      var deltaLat = Math.abs(maxLat - minLat);
      minLng -= deltaLng * 0.1;
      maxLng += deltaLng * 0.1;
      minLat -= deltaLat * 0.1;
      maxLat += deltaLat * 0.1;

      this.map.fitBounds([
        [
          minLng,
          minLat,
        ],
        [
          maxLng,
          maxLat,
        ]
      ]);
    }
  }

  private filterPiece(piece, filter) {
    let truthy1: boolean = true;
    let truthy2: boolean = true;
    let truthy3: boolean = true;

    let title = piece.title.toLowerCase();
    let artist = piece.artist.toLowerCase();
    let medium = piece.medium.toLowerCase();
    let description = piece.description.toLowerCase();
    let tags = piece.tags.toLowerCase();

    if (filter.dateRange) {
      if (
        piece.date < filter.dateRange.min ||
        piece.date > filter.dateRange.max
      ) {
        truthy1 = false;
      }
    }

    if (filter.exploreSelection) {
      if (piece.featured_search.indexOf(filter.exploreSelection) === -1) {
        truthy2 = false;
      }
    }

    if (filter.searchTerm) {
      let searchTermLowerCase = filter.searchTerm.toLowerCase();
      truthy3 = false;

      if (
        title.indexOf(searchTermLowerCase) !== -1 ||
        artist.indexOf(searchTermLowerCase) !== -1 ||
        medium.indexOf(searchTermLowerCase) !== -1 ||
        description.indexOf(searchTermLowerCase) !== -1 ||
        tags.indexOf(searchTermLowerCase) !== -1
      ) {
        truthy3 = true;
      }
    }

    return truthy1 && truthy2 && truthy3;
  }

  private getMinLng(data) {
    return data.reduce((min, p) => p.lng < min ? p.lng : min, data[0].lng);
  }
  private getMaxLng(data) {
    return data.reduce((max, p) => p.lng > max ? p.lng : max, data[0].lng);
  }
  private getMinLat(data) {
    return data.reduce((min, p) => p.lat < min ? p.lat : min, data[0].lat);
  }
  private getMaxLat(data) {
    return data.reduce((max, p) => p.lat > max ? p.lat : max, data[0].lat);
  }

  showNativeTerritories(event) {
    this.map.setLayoutProperty('native-territories', 'visibility', event ? 'visible' : 'none');
    this.map.setLayoutProperty('native-territories-labels', 'visibility', event ? 'visible' : 'none');
  }
}
