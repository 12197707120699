import { EventEmitter, Component, OnInit, Output } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  @Output()
  sidebarToggleClick: EventEmitter<boolean> = new EventEmitter(false);

  @Output()
  breakpointIndex: EventEmitter<number> = new EventEmitter();

  backButtonEnabled: boolean = false;

  @Output()
  backButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  sidebarToggled: boolean = false;

  breakpointSubscription: Subscription;

  constructor(public breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    this.breakpointSubscription =
      this.breakpointObserver
        .observe(['(min-width: 1001px)']) // TODO: access scss to get actual value or use predefined breakpoints in scss and here
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.toggleSidebar(false);
            this.breakpointIndex.emit(1);
          } else {
            this.breakpointIndex.emit(2);
          }
        });
  }

  onToggleSidebarClick() {
    this.toggleSidebar(!this.sidebarToggled);
  }

  onDestroy() {
    this.breakpointSubscription.unsubscribe();
  }

  private toggleSidebar(toggled: boolean) {
    this.sidebarToggled = toggled;
    this.sidebarToggleClick.emit(toggled);
  }

  showBackButton() {
    this.backButtonEnabled = true;
  }

  onBackButtonClick() {
    this.backButtonEnabled = false;
    this.backButtonClicked.emit();
  }
}
