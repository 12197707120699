import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { FeatureCollection, GeoJson } from './map';
import * as mapboxgl from 'mapbox-gl';

import nativeTerritories from './data/native-territories.json';
import pieces from './data/pieces.json';

@Injectable()
export class MapService {

  constructor() {
    mapboxgl.accessToken = environment.mapbox.accessToken;
  }

  getNativeTerritories(): any {
    return nativeTerritories;
  }

  getMarkers(): any {
    return pieces;
  }

  wrapMarkers(pieces: any): FeatureCollection {
    let geoJson: GeoJson[] = [];

    pieces.forEach((piece) => {
      geoJson.push(
        new GeoJson(
          [
            piece.lng,
            piece.lat
          ], {
            object_no: piece.object_no,
            title: piece.title,
            artist: piece.artist,
            date: piece.date,
            circa: piece.circa,
            medium: piece.medium,
            dimensions: piece.dimensions,
            description: piece.description,
            img: piece.img,
            tags: piece.tags,
            featured_search: piece.featured_search,
            document1: piece.document1,
            document1_thumb: piece.document1_thumb,
            document1_description: piece.document1_description,
            document2: piece.document2,
            document2_thumb: piece.document2_thumb,
            document2_description: piece.document2_description,
            document3: piece.document3,
            document3_thumb: piece.document3_thumb,
            document3_description: piece.document3_description,
            links: piece.links
          }
        )
      );
    });

    return new FeatureCollection(geoJson);
  }
}
